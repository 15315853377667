import { createReducer } from 'redux-starter-kit';
import { authActions } from '../actions';

const {
  authSuccess, authFailure, setLastAuthUserId, resyncAuthUser,
} = authActions;

export const usersInitialState = {
  authUser: null,
  lastAuthUserId: null,
  numberLoginAttemps: sessionStorage.getItem('NumberLoginAttemps') ? parseInt(sessionStorage.getItem('NumberLoginAttemps'), 10) : 0,
};

function setNumberLoginAttemps(numberLoginAttemps) {
  sessionStorage.setItem('NumberLoginAttemps', numberLoginAttemps);
  return numberLoginAttemps;
}

const authSuccessClbk = (state, action) => ({
  ...state,
  authUser: action.payload,
  numberLoginAttemps: setNumberLoginAttemps(0),
});

const authFailureClbk = (state) => ({
  ...state,
  authUser: null,
  numberLoginAttemps: setNumberLoginAttemps(state.numberLoginAttemps + 1),
});

const setLastAuthUserIdClbk = (state, action) => ({
  ...state,
  lastAuthUserId: action.payload,
});

const resyncAuthUserClbk = (state, action) => ({
  ...state,
  authUser: action.payload,
});

const usersReducer = createReducer(usersInitialState, {
  [authSuccess]: authSuccessClbk,
  [authFailure]: authFailureClbk,
  [setLastAuthUserId]: setLastAuthUserIdClbk,
  [resyncAuthUser]: resyncAuthUserClbk,
});

export default usersReducer;
