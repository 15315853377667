import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  FormGroup, FormHelperText, TextField, Typography,
} from '@material-ui/core';
import { Form, Label } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import {
  ACTIVE,
  DATE_TIME, DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR,
  CONDITION_TYPE_CALENDAR_DATE, CONDITION_TYPE_DATE_VAR,
  CONDITION_TYPE_INCLUSION_DATE, INACTIVE, INCLUSION_CREATOR,
  DELAY_CONDITION_COMPLETION_DATE, DELAY_CONDITION_USER_INPUT_DATE,
  UNIT_DAY, UNIT_MONTH, UNIT_YEAR, MAX_FOLLOW_UPS,
} from '../constants';
import LabeledInput from './LabeledInput';
import { nsOptions } from '../i18n';
import DatePicker from './DatePicker';
import { getDateInputPlaceholder } from './ElementDate';
import { DelMsgModal } from './MessageModal';
import ButtonConfirm from './ButtonConfirm';
import LabelStatus from './LabelStatus';
import Checkbox from './Checkbox';

const defaultFormData = {
  name: '',
  status: INACTIVE,
  project: null,
  recipient_type: INCLUSION_CREATOR,
  condition_type: CONDITION_TYPE_CALENDAR_DATE,
  content_header: '',
  content_body: '',
  delay_value: 0,
  delay_unit: UNIT_DAY,
  delay_condition: DELAY_CONDITION_COMPLETION_DATE,
  followup_value: 0,
  followup_value_unit: UNIT_DAY,
  followup_while_value: 0,
  followup_while_unit: UNIT_MONTH,
};

@withTranslation('', nsOptions)
class NotificationForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired,
    callbackDelete: PropTypes.func.isRequired,
    project: PropTypes.shape().isRequired,
    notificationToDisplay: PropTypes.shape(),
    format: PropTypes.oneOf([DATE_TIME, DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR]),
    dateElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
    notificationToDisplay: null,
    format: DATE_FULL,
  }

  constructor(props) {
    super(props);
    // Open the notificationToDisplay as an edit form or create a new one
    if (this.props.notificationToDisplay) {
      this.state = {
        activateFollowup: (this.props.notificationToDisplay.followup_value > 0),
        notification: {
          ...this.props.notificationToDisplay,
        },
      };
    } else {
      this.state = {
        activateFollowup: false,
        notification: {
          ...defaultFormData,
          project: this.props.project.id,
        },
      };
    }
  }

  render() {
    const {
      t, callback, callbackDelete, format,
    } = this.props;

    const conditionTypeOptions = [
      { fieldKey: 'condition_type', value: CONDITION_TYPE_CALENDAR_DATE, label: t('project:notification.form.sending-condition-type-calendar-date') },
      { fieldKey: 'condition_type', value: CONDITION_TYPE_DATE_VAR, label: t('project:notification.form.sending-condition-type-calendar-date-var') },
      { fieldKey: 'condition_type', value: CONDITION_TYPE_INCLUSION_DATE, label: t('project:notification.form.sending-condition-type-inclusion-date') },
    ];
    const dateVarOptions = this.props.dateElements.map((element) => ({
      fieldKey: 'date_variable_project_element',
      value: element.id,
      label: element.name && element.name !== '' ? element.name : t('common:elements.default-labels.variable'),
    }));
    const delayUnitOptions = [
      { fieldKey: 'delay_unit', value: UNIT_DAY, label: t('project:notification.form.day') },
      { fieldKey: 'delay_unit', value: UNIT_MONTH, label: t('project:notification.form.month') },
      { fieldKey: 'delay_unit', value: UNIT_YEAR, label: t('project:notification.form.year') },
    ];
    const delayConditionOptionsFull = [
      { fieldKey: 'delay_condition', value: DELAY_CONDITION_COMPLETION_DATE, label: t('project:notification.form.delay-completion-date') },
      { fieldKey: 'delay_condition', value: DELAY_CONDITION_USER_INPUT_DATE, label: t('project:notification.form.delay-user-input-date') },
    ];
    const delayConditionCompletionDateOnly = [
      { fieldKey: 'delay_condition', value: DELAY_CONDITION_COMPLETION_DATE, label: t('project:notification.form.delay-completion-date') },
    ];
    const followupValueUnitOptions = [
      { fieldKey: 'followup_value_unit', value: UNIT_DAY, label: t('project:notification.form.day') },
      { fieldKey: 'followup_value_unit', value: UNIT_MONTH, label: t('project:notification.form.month') },
      { fieldKey: 'followup_value_unit', value: UNIT_YEAR, label: t('project:notification.form.year') },
    ];
    const followupWhileUnitOptions = [
      { fieldKey: 'followup_while_unit', value: UNIT_DAY, label: t('project:notification.form.day') },
      { fieldKey: 'followup_while_unit', value: UNIT_MONTH, label: t('project:notification.form.month') },
      { fieldKey: 'followup_while_unit', value: UNIT_YEAR, label: t('project:notification.form.year') },
    ];

    const onSelectFieldChange = (option) => {
      if (option.fieldKey === 'condition_type') {
        this.delayUnitRef.setValue(delayUnitOptions[0], 'select-option');
        if (this.delayConditionRef) {
          if (option.value === CONDITION_TYPE_DATE_VAR) {
            this.delayConditionRef.setValue(delayConditionOptionsFull[0], 'select-option');
          } else {
            this.delayConditionRef.setValue(delayConditionCompletionDateOnly[0], 'select-option');
          }
        }
        this.setState({
          notification: {
            ...this.state.notification,
            delay_value: 0,
            [option.fieldKey]: option.value,
          },
        });
      } else {
        this.setState({
          notification: { ...this.state.notification, [option.fieldKey]: option.value },
        });
      }
    };

    const getTotalNumberOfFollowups = () => {
      const {
        followup_value: followupValue,
        followup_value_unit: followupValueUnit,
        followup_while_value: followupWhileValue,
        followup_while_unit: followupWhileUnit,
      } = this.state.notification;
      if (!followupValue || !followupValueUnit || !followupWhileValue || !followupWhileUnit) {
        return 0;
      }

      if (followupValue <= 0 || followupWhileValue <= 0) {
        return 0;
      }

      let followupValueInDays = followupValue;
      if (followupValueUnit === UNIT_MONTH) {
        followupValueInDays *= 30;
      } else if (followupValueUnit === UNIT_YEAR) {
        followupValueInDays *= 365;
      }

      let followupWhileDays = followupWhileValue;
      if (followupWhileUnit === UNIT_MONTH) {
        followupWhileDays *= 30;
      } else if (followupWhileUnit === UNIT_YEAR) {
        followupWhileDays *= 365;
      }

      const totalNumberOfFollowups = Math.floor(followupWhileDays / followupValueInDays);
      return totalNumberOfFollowups;
    };

    const isFormValid = () => {
      const formValues = this.state.notification;
      let error = '';
      if (!(formValues.name
        && formValues.content_header
        && formValues.content_body)) {
        error = t('project:notification.form.error');
      }
      if (getTotalNumberOfFollowups() > MAX_FOLLOW_UPS) {
        error = t('project:notification.form.error-too-many-followups');
      }
      if (error) {
        this.setState({ error });
        return false;
      }
      this.setState({ error: '' });
      return true;
    };

    return (
      <Form
        noValidate
        ref={(formRef) => {
          this.form = formRef;
        }}
      >
        <LabeledInput
          type="text"
          label={t('project:notification.form.name')}
          placeholder={t('project:notification.form.name-placeholder')}
          className="mb-3 font-weight-bold"
          name="notification-name"
          required
          value={this.state.notification.name}
          onChange={(e) => {
            const { value } = e.target;
            this.setState({
              notification: { ...this.state.notification, name: value },
            });
          }}
          ref={(input) => { this.nameInput = input; }}
        />
        <Label className="font-weight-bold mt-5">{t('project:notification.form.notification-status')}</Label>
        <LabelStatus
          status={this.state.notification.status}
          callback={() => this.setState({
            notification: {
              ...this.state.notification,
              status: this.state.notification.status === INACTIVE ? ACTIVE : INACTIVE,
            },
          })}
        />
        <Label className="font-weight-bold mt-5">{t('project:notification.form.scheduling')}</Label>
        <FormGroup className="ml-3 mb-2">
          <Label for="notification-type" className="font-weight-bold">
            {t('project:notification.form.sending-condition-type')}
          </Label>
          <Box className="d-flex align-items-end">
            <Select
              name="notification-type"
              id="notification-type"
              className="react-select date-type-select w-50 mr-3"
              classNamePrefix="react-select"
              isClearable={false}
              isSearchable={false}
              options={conditionTypeOptions}
              defaultValue={conditionTypeOptions.filter(
                (option) => option.value === this.state.notification.condition_type,
              )}
              onChange={onSelectFieldChange}
              styles={{
                singleValue: (provided) => ({
                  ...provided,
                  maxWidth: 'unset',
                  minWidth: '120px',
                }),
              }}
            />
            <Box className="w-50">
              {this.state.notification.condition_type === CONDITION_TYPE_CALENDAR_DATE && (
              <DatePicker
                placeholder={getDateInputPlaceholder(t, format)}
                minDate={moment().startOf('day')}
                defaultValue={
                      this.state.notification.calendar_date
                        ? moment(this.state.notification.calendar_date) : null
                      }
                onChange={(value) => {
                  const formattedValue = value ? moment(value).format('YYYY-MM-DD') : null;
                  this.setState({
                    notification: { ...this.state.notification, calendar_date: formattedValue },
                  });
                }}
              />
              )}
              {this.state.notification.condition_type === CONDITION_TYPE_DATE_VAR && (
                <>
                  {this.props.dateElements.length === 0 && (
                    <FormHelperText className="small">{t('project:notification.form.no-date-var-available')}</FormHelperText>
                  )}
                  {this.props.dateElements.length > 0 && (
                  <Select
                    name="date_variable_project_element"
                    id="date_variable_project_element"
                    className="react-select date-type-select w-50 mr-3"
                    classNamePrefix="react-select"
                    isSearchable={false}
                    options={dateVarOptions}
                    defaultValue={dateVarOptions.filter((option) => {
                      const defaultValue = this.state.notification[dateVarOptions[0].fieldKey];
                      return option.value === defaultValue;
                    })}
                    isClearable={false}
                    onChange={onSelectFieldChange}
                    styles={{
                      singleValue: (provided) => ({
                        ...provided,
                        maxWidth: 'unset',
                        minWidth: '120px',
                      }),
                    }}
                  />
                  )}
                </>
              )}
            </Box>
          </Box>
          <FormHelperText className="small">{t('project:notification.form.sending-time-info')}</FormHelperText>
        </FormGroup>
        <Label className="font-weight-bold mt-5">{t('project:notification.form.add-delay')}</Label>
        <FormGroup className="ml-3 mt-0 mb-2" style={{ opacity: this.state.notification.condition_type !== CONDITION_TYPE_CALENDAR_DATE ? 1 : 0.5 }}>
          <Box className="d-flex align-items-end">
            <TextField
              type="number"
              className="small-text-field"
              value={this.state.notification.delay_value}
              disabled={this.state.notification.condition_type === CONDITION_TYPE_CALENDAR_DATE}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({
                  notification: { ...this.state.notification, delay_value: value },
                });
              }}
            />
            <Select
              name="delay_unit"
              id="delay_unit"
              className="react-select bottom-ligne date-type-select mx-1"
              classNamePrefix="react-select"
              isSearchable={false}
              options={delayUnitOptions}
              defaultValue={delayUnitOptions.filter((option) => {
                const defaultValue = this.state.notification[delayUnitOptions[1].fieldKey];
                return option.value === defaultValue;
              })}
              isClearable={false}
              onChange={onSelectFieldChange}
              isDisabled={this.state.notification.condition_type === CONDITION_TYPE_CALENDAR_DATE}
              ref={(ref) => { this.delayUnitRef = ref; }}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '90px',
                }),
              }}
            />
            {this.state.notification.condition_type === CONDITION_TYPE_INCLUSION_DATE && (
              <Typography className="mx-1" variant="body2">
                {t('project:notification.form.delay-after-completion-date')}
              </Typography>
            )}
            {this.state.notification.condition_type === CONDITION_TYPE_DATE_VAR && (
              <>
                <Typography className="mx-1" variant="body2">
                  {t('project:notification.form.delay-after')}
                </Typography>
                <Select
                  name="delay_condition"
                  id="delay_condition"
                  className="react-select bottom-ligne date-type-select mx-1"
                  classNamePrefix="react-select"
                  isSearchable={false}
                  options={this.state.notification.condition_type === CONDITION_TYPE_DATE_VAR
                    ? delayConditionOptionsFull : delayConditionCompletionDateOnly}
                  defaultValue={delayConditionOptionsFull.filter((option) => {
                    const defaultIndex = delayConditionCompletionDateOnly[0];
                    const defaultValue = this.state.notification[defaultIndex.fieldKey];
                    return option.value === defaultValue;
                  })}
                  isClearable={false}
                  onChange={onSelectFieldChange}
                  isDisabled={
                    this.state.notification.condition_type === CONDITION_TYPE_CALENDAR_DATE
                  }
                  ref={(ref) => { this.delayConditionRef = ref; }}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: '300px',
                    }),
                  }}
                />
              </>
            )}
          </Box>
        </FormGroup>
        <Label className="font-weight-bold mt-5">{t('project:notification.form.follow-up')}</Label>
        <Checkbox
          id="activate-followup"
          checked={this.state.activateFollowup || false}
          onChange={(e) => {
            if (e.target.checked) {
              this.setState({ activateFollowup: e.target.checked });
            } else {
              this.setState({
                activateFollowup: e.target.checked,
                notification: {
                  ...this.state.notification,
                  followup_value: 0,
                  followup_while_value: 0,
                },
              });
            }
          }}
        >
          {t('project:notification.form.activate-follow-up')}
        </Checkbox>
        <FormGroup className="ml-3 mt-0 mb-2" style={{ opacity: this.state.activateFollowup === true ? 1 : 0.5 }}>
          <Box className="d-flex align-items-end">
            <Typography className="mx-1" variant="body2">
              {t('project:notification.form.follow-up-every')}
            </Typography>
            <TextField
              type="number"
              className="small-text-field"
              value={this.state.notification.followup_value}
              disabled={!this.state.activateFollowup}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({
                  notification: { ...this.state.notification, followup_value: value },
                });
              }}
            />
            <Select
              name="followup_value_unit"
              id="followup_value_unit"
              className="react-select bottom-ligne date-type-select mx-1"
              classNamePrefix="react-select"
              isSearchable={false}
              options={followupValueUnitOptions}
              defaultValue={followupValueUnitOptions.filter((option) => {
                const defaultValue = this.state.notification[followupValueUnitOptions[0].fieldKey];
                return option.value === defaultValue;
              })}
              isClearable={false}
              isDisabled={!this.state.activateFollowup}
              onChange={onSelectFieldChange}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '90px',
                }),
              }}
            />
            <Typography className="mx-1" variant="body2">
              {t('project:notification.form.follow-up-while')}
            </Typography>
            <TextField
              type="number"
              className="small-text-field"
              value={this.state.notification.followup_while_value}
              disabled={!this.state.activateFollowup}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({
                  notification: { ...this.state.notification, followup_while_value: value },
                });
              }}
            />
            <Select
              name="followup_while_unit"
              id="followup_while_unit"
              className="react-select bottom-ligne date-type-select mx-1"
              classNamePrefix="react-select"
              isSearchable={false}
              options={followupWhileUnitOptions}
              defaultValue={followupWhileUnitOptions.filter((option) => {
                const defaultValue = this.state.notification[followupWhileUnitOptions[0].fieldKey];
                return option.value === defaultValue;
              })}
              isClearable={false}
              isDisabled={!this.state.activateFollowup}
              onChange={onSelectFieldChange}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: '90px',
                }),
              }}
            />
          </Box>
          {this.state.activateFollowup && (
            <FormHelperText
              className="d-flex align-items-end"
              error={getTotalNumberOfFollowups() > MAX_FOLLOW_UPS}
            >
              {`${getTotalNumberOfFollowups()} ${t('project:notification.form.follow-up-will-be-sent')}`}
            </FormHelperText>
          )}
        </FormGroup>
        <Label className="font-weight-bold mt-5">{t('project:notification.form.notification-content')}</Label>
        <FormGroup className="ml-3 mt-1 mb-2">
          <Label for="content_header" className="font-weight-bold">{t('project:notification.form.notification-content-header')}</Label>
          <LabeledInput
            type="text"
            className="mb-3"
            name="content_header"
            placeholder={t('project:notification.form.notification-content-header-placeholder')}
            validation="mail_subject"
            required
            value={this.state.notification.content_header}
            onChange={(e) => {
              const { value } = e.target;
              this.setState({
                notification: { ...this.state.notification, content_header: value },
              });
            }}
          />
          <Label for="content_body" className="font-weight-bold">{t('project:notification.form.notification-content-message')}</Label>
          <div className="fake-input-with-border description-input">
            <textarea
              id="content_body"
              name="content_body"
              required
              value={this.state.notification.content_body}
              placeholder={t('project:notification.form.notification-content-message-placeholder')}
              onChange={(e) => {
                const { value } = e.target;
                this.setState({
                  notification: { ...this.state.notification, content_body: value },
                });
              }}
            />
          </div>
        </FormGroup>
        <Box className="d-flex align-items-center justify-content-end my-5">
          <Typography className="mx-2" variant="body2" color="error">
            {this.state.error}
          </Typography>
          <button
            type="button"
            className="btn btn-newblue-1 text-white mr-2"
            disabled={false}
            onClick={() => {
              if (isFormValid()) {
                callback(this.state.notification);
              }
            }}
          >
            <FontAwesomeIcon
              icon={['far', 'check']}
              transform="grow-3"
              className="mr-2"
            />
            <span className="ml-1">
              {t('common:button.validate')}
            </span>
          </button>
          <DelMsgModal
            message={(
              <span>
                <b>
                  {t('common:caution')}
                  &nbsp;!
                </b>
                <br />
                <br />
                {t('project:notification.notification-warning')}
              </span>
            )}
            onValidate={() => {
              const id = this.props.notificationToDisplay ? this.state.notification.id : null;
              callbackDelete(id);
            }}
          >
            <ButtonConfirm>
              <button
                type="button"
                className="btn btn-yellow"
              >
                <span>
                  <FontAwesomeIcon
                    icon={['far', 'trash-alt']}
                    className="mr-2"
                  />
                  {t('common:button.delete')}
                </span>
              </button>
            </ButtonConfirm>
          </DelMsgModal>
        </Box>
      </Form>
    );
  }
}

export default NotificationForm;
