import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { Mutex } from 'async-mutex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import AuthContainer from '../components/AuthContainer';
import LabeledInput from '../components/LabeledInput';
import Page, { checkNotAuth } from '../components/Page';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import { setLanguage } from '../utils/language';
import { authActions, miscActions } from '../redux/actions';
import { WEBSITE_URL } from '../constants';
import NewTooltip from '../components/NewTooltip';
import isNumberLoginAttemptsExceeded from '../utils/auth';


const mapStateToProps = (state) => ({
  lastAuthUserId: state.auth.lastAuthUserId,
  pendingUrl: state.misc.pendingUrl,
});

const mapDispatchToProps = (dispatch) => ({
  auth: async (email, password) => dispatch(authActions.auth(email, password)),
  logout: async (resetStore) => dispatch(authActions.logout(resetStore)),
  clearPendingUrl: () => dispatch(miscActions.clearPendingUrl()),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class Login extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    lastAuthUserId: PropTypes.number,
    auth: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    clearPendingUrl: PropTypes.func.isRequired,
    pendingUrl: PropTypes.string,
  };

  static defaultProps = {
    lastAuthUserId: undefined,
    pendingUrl: undefined,
  };

  constructor(props) {
    super(props);
    this.mutex = new Mutex();

    if (isNumberLoginAttemptsExceeded()) {
      history.push('/auth/recover');
    }
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') this.handleFormSubmit(event);
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();
    const { emailInput, passwordInput } = this;
    const {
      auth, logout, i18n, lastAuthUserId, clearPendingUrl,
    } = this.props;

    if (emailInput && passwordInput && !this.mutex.isLocked()) {
      const release = await this.mutex.acquire();

      try {
        const user = await auth(emailInput.value, passwordInput.value);
        if (user && user.last_password_change_date && moment().diff(moment(user.last_password_change_date), 'months') >= 12) {
          console.error('Your password is older than 12 months, please change it');
          await logout(true);
          history.push('/auth/recover?passwordExpired=true');
        } else {
          await setLanguage(i18n, user.language);
          if (lastAuthUserId && lastAuthUserId !== user.id) {
            clearPendingUrl();
          }
          Toast.success(this.props, 'error:valid.connected', 2000);
          history.push('/');
        }
      } catch (error) {
        await logout(false);
        ErrorUtil.handleCatched(this.props, error);
        if (isNumberLoginAttemptsExceeded()) {
          history.push('/auth/recover');
        }
      } finally {
        release();
      }
    }
  };

  isInvitationPending = () => {
    const { pendingUrl } = this.props;
    if (pendingUrl) {
      const regex = new RegExp('^.*/dashboard/join-project/.*$');
      return pendingUrl.match(regex);
    }
    return false;
  };

  render() {
    const { t } = this.props;
    return (
      <Page
        {...this.props}
        title={t('auth:login')}
        checkAuthorizations={[checkNotAuth]}
      >
        <AuthContainer
          top={this.isInvitationPending() ? {
            subTitle: (
              <div className="mt-4">
                <FontAwesomeIcon icon={['fal', 'envelope-open-text']} transform="grow-6" />
                {' '}
                <div className="mt-3">
                  {t('auth:pages.login.invitation-pending.sub-title')}
                </div>
              </div>
            ),
            info: (
              <div className="mt-3 login-invitation-info">
                <span>
                  {t('auth:pages.login.invitation-pending.info-1')}
                </span>
                {' '}
                <NewTooltip content={t('auth:pages.login.invitation-pending.tooltip')}>
                  <Link className="text-underline text-newturquoise-1" to="/auth/register">
                    {t('auth:pages.login.invitation-pending.info-2')}
                  </Link>
                </NewTooltip>
                .
              </div>
            ),
          } : {
            info: t('auth:pages.login.info'),
          }}
          bottom={{
            navContent: (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button
                    className="btn btn-newturquoise-1"
                    onClick={this.handleFormSubmit}
                  >
                    {t('auth:button.login')}
                  </button>
                </div>
                <div className="col-12">
                  <div className="authentication-small-info">
                    <Link to="/auth/recover">
                      {t('auth:button.forgotten-password')}
                    </Link>
                    |
                    <Link to="/auth/register">
                      {t('auth:button.register-to-doqboard')}
                    </Link>
                    |
                    <a href={WEBSITE_URL} target="_blank" rel="noopener noreferrer">
                      {t('auth:button.visit-website')}
                    </a>
                  </div>
                </div>
              </div>
            ),
          }}
        >
          <div className="authentication-form px-0 px-sm-5 mx-auto">
            <LabeledInput
              type="email"
              name="email"
              label={t('user:email')}
              placeholder={t('error:placeholder.email')}
              required
              onValidChange={(e) => {
                this.emailInput = e.target;
              }}
              onKeyPress={this.onKeyPress}
              autoFocus
            />
            <LabeledInput
              type="password"
              name="password"
              label={t('user:password')}
              placeholder={t('error:placeholder.password')}
              required
              onValidChange={(e) => {
                this.passwordInput = e.target;
              }}
              onKeyPress={this.onKeyPress}
            />
          </div>
        </AuthContainer>
      </Page>
    );
  }
}


export default Login;
