import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import AuthContainer from '../components/AuthContainer';
import Page from '../components/Page';
import PasswordInput from '../components/PasswordInput';
import api from '../api';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';


@withToastManager
@withTranslation('', nsOptions)
class PasswordReset extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string,
        token: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props, []);
    this.state = { password: '', confirmationPassword: '' };
    api.logout();
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();
    const { password, confirmationPassword } = this.state;
    if (password && confirmationPassword) {
      if (password !== confirmationPassword) {
        Toast.error(this.props, 'error:error.passwords-do-not-match');
        return;
      }
      try {
        await api.create(`reset-password/${this.props.match.params.token}`, { password });
        Toast.success(this.props, 'error:valid.success');
        Toast.success(this.props, 'error:valid.can-now-connect', 4000);
        history.push('/');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
        Toast.error(this.props, 'error:error.please-open-email-again');
        history.push('/');
      }
    }
  };

  render() {
    const { t } = this.props;
    const { password, confirmationPassword } = this.state;

    return (
      <Page
        {...this.props}
        title={t('auth:password-reset')}
        checkAuthorizations={[]}
      >
        <AuthContainer
          top={{
            info: t('auth:pages.password-reset.info'),
          }}
          bottom={{
            navContent: (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <button className="btn btn-newblue-1" onClick={this.handleFormSubmit}>
                    {t('user:button.validate-password')}
                  </button>
                </div>
                <div className="col-auto">
                  <Link to="/" className="btn btn-link">
                    {t('common:button.cancel')}
                  </Link>
                </div>
              </div>
            ),
          }}
        >
          <div className="authentication-form py-4 px-5">
            <PasswordInput
              label={t('user:new-password')}
              name="password"
              placeholder={t('error:placeholder.password')}
              validation="password"
              required
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
          </div>
          <div className="authentication-form py-4 px-5">
            <PasswordInput
              label={t('user:new-password-confirmation')}
              name="confirmationPassword"
              placeholder={t('error:placeholder.password')}
              validation="password"
              required
              value={confirmationPassword}
              onChange={(e) => {
                this.setState({ confirmationPassword: e.target.value });
              }}
            />
          </div>
        </AuthContainer>
      </Page>
    );
  }
}

export default PasswordReset;
